import React from 'react';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quotes: [
        'Imagination is more important than knowledge.',
        'Two things are infinite: the universe and human stupidity; and I\'m not sure about the universe.',
        'Imagination is everything. It is the preview of life\'s coming attractions.',
        'In the middle of difficulty lies opportunity.',
        'Life is like riding a bicycle. To keep your balance you must keep moving.',
        'The important thing is not to stop questioning. Curiosity has its own reason for existing.',
        'If you can\'t explain it simply, you don\'t understand it well enough.',
        // 'No problem can be solved from the same level of consciousness that created it.',
        'Anyone who has never made a mistake has never tried anything new.'
      ]
    }
  }

  componentDidMount() {
    // populate a staring quote.
    this.getRandomQuote();

    // setup the interval to replace the quote.
    this.timerID = setInterval(
      () => this.getRandomQuote(),
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  getRandomQuote() {
    const i = Math.floor(Math.random() * this.state.quotes.length);
    // console.log('returning a new quote...', i);

    this.setState({
      selectedQuote: this.state.quotes[i]
    })
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            &ldquo;{ this.state.selectedQuote }&ldquo;
            <br />
            <em>Albert Einstein</em>
          </p>
        </header>
      </div>
    );
  }
}
export default App;
